import { Box, Collapse, Grid, IconButton, Paper, Tooltip, Typography } from '@material-ui/core';
import { Chip, LinearProgress } from 'components/Common';
import { BouncingDots, ChevronDown, ChevronUp, Edit, Info, List } from 'components/Common/Icons';
import { InOutFlow } from 'components/Dashboard/Statistics';
import { format } from 'date-fns';
import { i18n } from 'locale';
import React, { useState } from 'react';
import { NormalizedUnit } from 'types';
import { UnitEditModal } from '..';
import SubUnitsTableRow from './SubUnitsTableRow';

export type UnitsTableRowProps = NormalizedUnit;

const UnitsTableRow: React.FC<UnitsTableRowProps> = ({
  name,
  availableSpots,
  capacity,
  input,
  output,
  input24,
  output24,
  input48,
  output48,
  units,
  isEditing,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [editing, setEditing] = useState<boolean>(isEditing);

  const capacityRate = Math.floor(((capacity - availableSpots) * 100) / capacity);
  const capacityColor = capacityRate > 90 ? 'error' : capacityRate > 70 ? 'warning' : 'success';

  return (
    <Grid style={{ padding: '16px 0px' }} component={Paper} container>
      <Grid item container alignItems="center">
        <Grid item xs={3}>
          <Grid item container justify="space-between" alignItems="center">
            <Box paddingLeft={4}>
              <Typography variant="h5">{name}</Typography>
            </Box>
            {units && units.length !== 0 && (
              <Grid item xs={4}>
                <Grid item container alignItems="center" justify="space-evenly">
                  <List color="secondary" />
                  <Typography variant="body2">{units.length}</Typography>
                  <IconButton size="small" onClick={() => setOpen(!open)}>
                    {open ? <ChevronUp fontSize="default" /> : <ChevronDown fontSize="default" />}
                  </IconButton>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item xs={2}>
          <Grid container alignItems="center" spacing={3}>
            <Grid item xs={5}>
              <LinearProgress value={capacityRate} color={capacityColor} />
            </Grid>
            <Grid item>
              <Typography variant="body2">{capacityRate}%</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={1}>
          <Grid container item alignItems="center" spacing={2}>
            <Grid item xs={3}>
              <Typography variant="body2">{availableSpots}</Typography>
            </Grid>
            <Grid item>
              <Tooltip
                title={
                  <>
                    <div>
                      <span>{i18n.man} : </span>
                      12
                    </div>
                    <div>
                      <span>{i18n.woman} : </span>
                      14
                    </div>
                    <div>
                      <span>{i18n.indifferent} : </span>4
                    </div>
                  </>
                }
              >
                <Grid container alignItems="center">
                  <Info color="secondary" />
                </Grid>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={2}>
          <InOutFlow input={input} output={output} />
        </Grid>
        <Grid item xs={1}>
          <InOutFlow input={input24} output={output24} />
        </Grid>
        <Grid item xs={1}>
          <InOutFlow input={input48} output={output48} />
        </Grid>
        <Grid item xs={2}>
          <Box paddingRight={4}>
            <Grid container alignItems="center" justify="space-between">
              <Grid item>
                {isEditing ? (
                  <Chip icon={<BouncingDots fontSize="default" />} label={i18n.isEditing} reverse />
                ) : (
                  <Typography variant="subtitle2">{format(new Date(), 'dd/MM/yyyy à HH:mm')}</Typography>
                )}
              </Grid>
              <Grid item>
                <IconButton
                  size="small"
                  onClick={() => {
                    setModalOpen(true);
                    setEditing(!editing);
                  }}
                >
                  <Edit />
                </IconButton>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>

      <Collapse in={open}>
        <Grid container direction="column" spacing={4} style={{ marginTop: 24 }}>
          {units?.map((subUnit) => {
            return (
              <Grid item key={subUnit.objectId}>
                <SubUnitsTableRow {...subUnit} />
              </Grid>
            );
          })}
        </Grid>
      </Collapse>

      <UnitEditModal
        units={units || []}
        name={name}
        open={isModalOpen}
        onClose={() => {
          setModalOpen(false);
          setEditing(!isEditing);
        }}
      />
    </Grid>
  );
};

export default UnitsTableRow;
