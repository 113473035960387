import { useEffect, useRef, useState } from 'react';
import { CircleMarker, MapContainer, Marker, TileLayer } from 'react-leaflet';

const HealthCenterMap: React.FC = () => {
  const ref = useRef<HTMLDivElement>(null);
  const [mapSize, setMapSize] = useState(0);

  const handleResize = () => {
    if (ref.current) {
      const mapTop = ref.current?.getBoundingClientRect().top;
      const size = window.innerHeight - mapTop;
      setMapSize(size);
    }
  };

  useEffect(() => {
    handleResize();
  }, [ref]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div ref={ref} style={{ height: mapSize, width: '100%' }}>
      {ref.current && (
        <MapContainer
          style={{ height: '100%', width: '100%' }}
          center={[48.108, -1.6833]}
          zoom={10}
          attributionControl={false}
        >
          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <CircleMarker center={[48.108, -1.6833]} radius={10} fill fillColor="#0099CB" />
          <Marker position={[48.108, -1.6833]} />
        </MapContainer>
      )}
    </div>
  );
};

export default HealthCenterMap;
