import { PageHeader } from 'components/Common/Navigation';
import Layout from 'modules/Layout';

const MyUnit = () => {
  return (
    <Layout>
      <PageHeader title="Cardiologie" />
    </Layout>
  );
};

export default MyUnit;
