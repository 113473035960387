import { Box, Grid, Tooltip, Typography } from '@material-ui/core';
import { Chip, LinearProgress } from 'components/Common';
import { Info, Phone } from 'components/Common/Icons';
import { InOutFlow } from 'components/Dashboard/Statistics';
import { i18n } from 'locale';
import { NormalizedUnit } from 'types';

type SubUnitsTableRowProps = NormalizedUnit;

const SubUnitsTableRow: React.FC<SubUnitsTableRowProps> = ({
  name,
  availableSpots,
  capacity,
  input,
  output,
  input24,
  output24,
  input48,
  output48,
  isCovidUnit,
}) => {
  const capacityRate = Math.floor(((capacity - availableSpots) * 100) / capacity);
  const capacityColor = capacityRate > 90 ? 'error' : capacityRate > 70 ? 'warning' : 'success';

  return (
    <Grid container>
      <Grid item xs={3}>
        <Box paddingLeft={6} paddingRight={28}>
          <Grid container justify="space-between" alignItems="center">
            <Grid item>
              <Typography variant="h5">{name}</Typography>
            </Grid>
            {isCovidUnit && (
              <Grid item>
                <Box margin={-2}>
                  <Chip label="COVID" color="error" />
                </Box>
              </Grid>
            )}
            <Grid item>
              <Tooltip title="06 12 34 56 78">
                <Grid container alignItems="center">
                  <Phone color="secondary" />
                </Grid>
              </Tooltip>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid item xs={2}>
        <Grid container alignItems="center" spacing={3}>
          <Grid item xs={5}>
            <LinearProgress value={capacityRate} color={capacityColor} />
          </Grid>
          <Grid item>
            <Typography variant="body2">{capacityRate}%</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={1}>
        <Grid container item alignItems="center" spacing={2}>
          <Grid item xs={3}>
            <Typography variant="body2">{availableSpots}</Typography>
          </Grid>
          <Grid item>
            <Tooltip
              title={
                <>
                  <div>
                    <span>{i18n.man} : </span>
                    12
                  </div>
                  <div>
                    <span>{i18n.woman} : </span>
                    14
                  </div>
                  <div>
                    <span>{i18n.indifferent} : </span>4
                  </div>
                </>
              }
            >
              <Grid container alignItems="center">
                <Info color="secondary" />
              </Grid>
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={2}>
        <InOutFlow input={input} output={output} />
      </Grid>
      <Grid item xs={1}>
        <InOutFlow input={input24} output={output24} />
      </Grid>
      <Grid item xs={1}>
        <InOutFlow input={input48} output={output48} />
      </Grid>
    </Grid>
  );
};

export default SubUnitsTableRow;
