import React, { createContext, useEffect, useState } from 'react';
import { NormalizedUnit } from 'types';

type UnitsContextType = {
  units: NormalizedUnit[];
  loading: boolean;
};

export const createUnit = (
  objectId: string,
  name: string,
  availableSpots: number,
  capacity: number,
  covidCapacity: number,
  lastUpdatedAt: Date,
  units?: NormalizedUnit[],
  input: number = 2,
  output: number = 4,
  input24: number = 2,
  output24: number = 4,
  input48: number = 2,
  output48: number = 4,
  isCovidUnit: boolean = false,
  isExcludedFromTotal: boolean = false,
  isEditing: boolean = false
) => ({
  objectId,
  loading: false,
  name,
  availableSpots,
  capacity,
  covidCapacity,
  lastUpdatedAt,
  units,
  input,
  output,
  input24,
  output24,
  input48,
  output48,
  isCovidUnit,
  isExcludedFromTotal,
  isEditing,
});

const fakeSubUnits: NormalizedUnit[] = [
  createUnit('6', 'Cardiologie', 2, 20, 1, new Date(), undefined, 2, 3, 1, 0, 0, 1),
  createUnit('7', 'Pneumologie', 5, 10, 1, new Date(), undefined, 1, 3, 0, 0, 0, 0, true),
  createUnit('8', 'Gériatrie', 3, 13, 1, new Date(), undefined, 3, 0, 0, 0, 0, 1),
];

const fakeUnits: NormalizedUnit[] = [
  createUnit('1', 'F.M.E', 8, 43, 3, new Date(), fakeSubUnits, 4, 7, 7, 1, 0, 1),
  createUnit('2', 'Chirurgie', 25, 43, 1, new Date(), fakeSubUnits, 3, 6, 1, 4, 2, 1, true),
  createUnit('3', 'Urgences', 5, 43, 2, new Date(), fakeSubUnits, 7, 5, 4, 2, 2, 5, false, true, true),
  createUnit('4', 'Spécifiques', 1, 43, 1, new Date(), fakeSubUnits, 9, 3, 3, 0, 3, 0, true),
  createUnit('5', 'Médecine', 10, 43, 2, new Date(), fakeSubUnits, 5, 6, 1, 0, 0, 2, true, false),
];

const UnitsContext = createContext({} as UnitsContextType);

export const UnitsContextProvider: React.FC = ({ children }) => {
  const [units, setUnits] = useState<NormalizedUnit[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchUnits = () => {
      setUnits(fakeUnits);
      setLoading(false);
    };

    const timer = setTimeout(fetchUnits, 1000);
    return () => clearTimeout(timer);
  }, []);

  const value = { units, loading };

  return <UnitsContext.Provider value={value}>{children}</UnitsContext.Provider>;
};

export default UnitsContext;
