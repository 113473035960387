import { Grid, makeStyles, SvgIconProps, Typography } from '@material-ui/core';
import { CircularProgressIcon } from 'components/Common';
import StatisticsCardSkeleton from './StatisticsCardSkeleton';
const colorRule = {
  low: 70,
  high: 90,
};

export type StatisticsCardItemProps = {
  label: string;
  value: number | string;
  loading?: boolean;
  displayProgress?: boolean;
  percentage?: boolean;
  total?: number;
  totalLabel?: string;
  Icon?: React.FC<SvgIconProps>;
};

type PaletteColor = 'primary' | 'secondary' | 'warning' | 'error' | 'success';
const useStyles = makeStyles(({ palette }) => ({
  value: {
    color: ({ color }: { color?: PaletteColor }) => (color ? palette[color].main : 'inherit'),
  },
}));

const StatisticsCardItem: React.FC<StatisticsCardItemProps> = ({
  label,
  value,
  displayProgress,
  totalLabel,
  percentage,
  total,
  Icon,
  loading,
}) => {
  const color =
    displayProgress && typeof value === 'number'
      ? value > colorRule.low
        ? value > colorRule.high
          ? 'error'
          : 'warning'
        : 'success'
      : undefined;
  const styles = useStyles({ color });
  return (
    <Grid item>
      {loading ? (
        <StatisticsCardSkeleton displayProgress={displayProgress} />
      ) : (
        <Grid item container alignItems="center" spacing={2}>
          {!!color && !!Icon && typeof value === 'number' && (
            <Grid item>
              <CircularProgressIcon value={value} color={color} Icon={Icon} />
            </Grid>
          )}
          <Grid item>
            <Typography variant="subtitle2" noWrap>
              {label}
            </Typography>
            <Typography variant="h2" className={styles.value} component="span">
              {value}
              {percentage && '%'}
              {total && totalLabel && <Typography variant="caption">/{`${total} ${totalLabel}`}</Typography>}
            </Typography>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};
export default StatisticsCardItem;
