export const getCookie = (name: string): string | undefined => {
  const cookie = document.cookie.split(';').find((cookie) => cookie.trim().startsWith(name + '='));

  if (cookie) {
    return cookie.split('=')[1];
  } else {
    return undefined;
  }
};

export const removeCookie = (name: string, domain: string, path: string): string =>
  (document.cookie = `${name}=;path=${path};domain=${domain};expires=Thu, 01 Jan 1970 00:00:01 GMT');`);

export const setCookie = (name: string, value?: string, domain?: string, path?: string): string =>
  (document.cookie = `${name}=${value};domain=${domain};path=${path}`);
