import { Box, Grid, Typography } from '@material-ui/core';

export type PageHeaderProps = {
  title: string;
};

const PageHeader: React.FC<PageHeaderProps> = ({ title, children }) => {
  return (
    <Grid container item justify="space-between" alignItems="center">
      <Grid item>
        <Typography variant="h1">{title}</Typography>
      </Grid>
      <Grid item>
        <Box marginY={-4}>{children}</Box>
      </Grid>
    </Grid>
  );
};

export default PageHeader;
