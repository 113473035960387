import UserContext from 'context/UserContext';
import { Login, MyHealthCenter, MyUnit, TerritorialReporting } from 'pages';
import { useContext } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { UnitsContextProvider } from './context/UnitsContext';

const App = () => {
  const { isLoggedIn } = useContext(UserContext);
  return isLoggedIn ? (
    <UnitsContextProvider>
      <Switch>
        <Route path="/reporting/territorial">
          <TerritorialReporting />
        </Route>
        <Route path="/management/my-health-center">
          <MyHealthCenter />
        </Route>
        <Route path="/management/my-unit">
          <MyUnit />
        </Route>
        <Redirect to="/management/my-health-center" />
      </Switch>
    </UnitsContextProvider>
  ) : (
    <Login />
  );
};

export default App;
