import { Box, Grid, Typography } from '@material-ui/core';
import { Button } from 'components/Common';
import { MultipleSelect } from 'components/Common/Form';
import { UnitsTable } from 'components/Dashboard/Unit';
import { UnitsContext } from 'context';
import { useContext, useState } from 'react';
import { NormalizedUnit } from 'types';

const arrayToObject = (array: NormalizedUnit[]) =>
  array.reduce((options, { name, objectId }) => ({ ...options, [objectId]: name }), {} as Record<string, string>);

const HealthCenterUnitsReporting = () => {
  const { units, loading } = useContext(UnitsContext);

  const [serviceFilter, setServiceFilter] = useState<string[]>([]);
  const [unitFilter, setUnitFilter] = useState<string[]>([]);
  const [occupationRateFilter, setOccupationRateFilter] = useState<string[]>([]);

  const serviceOptions = arrayToObject(units);

  const unitsOptions = units.reduce(
    (options, { units }) => ({ ...options, ...arrayToObject(units || []) }),
    {} as Record<string, string>
  );

  const handleResetFilter = () => {
    setServiceFilter([]);
    setUnitFilter([]);
    setOccupationRateFilter([]);
  };

  let filteredUnits = units;

  if (serviceFilter.length > 0) {
    filteredUnits = filteredUnits.filter(({ objectId }) => serviceFilter.includes(objectId));
  }

  if (occupationRateFilter.length > 0) {
    filteredUnits = filteredUnits.filter(({ capacity, availableSpots }) => {
      const rate = Math.floor(((capacity - availableSpots) * 100) / capacity);

      let isFiltered = false;
      for (const filter of occupationRateFilter) {
        isFiltered = isFiltered || (rate < parseInt(filter.split('to')[1]) && rate > parseInt(filter.split('to')[0]));
      }
      return isFiltered;
    });
  }

  if (unitFilter.length > 0) {
    filteredUnits = filteredUnits.filter(({ units }) => {
      let isFiltered = false;
      units?.forEach(({ objectId }) => {
        isFiltered = isFiltered || unitFilter.includes(objectId);
      });
      return isFiltered;
    });
  }

  const displayReset = serviceFilter.length > 0 || occupationRateFilter.length > 0 || unitFilter.length > 0;

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <Typography variant="h3">Vue par pôles</Typography>
      </Grid>
      <Grid item container spacing={2} alignItems="center">
        <Grid item>
          <MultipleSelect label="Pôle" options={serviceOptions} value={serviceFilter} onChange={setServiceFilter} />
        </Grid>
        <Grid item>
          <MultipleSelect label="Unité" options={unitsOptions} value={unitFilter} onChange={setUnitFilter} />
        </Grid>
        <Grid item>
          <MultipleSelect
            label="Taux d'occupation"
            options={{ '0to70': 'Inférieur à 70%', '70to90': 'Entre 70% et 90%', '90to100': 'Supérieur à 90%' }}
            value={occupationRateFilter}
            onChange={setOccupationRateFilter}
          />
        </Grid>
        {displayReset && (
          <Grid item>
            <Button variant="text" onClick={handleResetFilter}>
              Réinitialiser
            </Button>
          </Grid>
        )}
      </Grid>
      <Grid item>
        <Box bgcolor="secondary.light" marginX={-10}>
          <Box paddingX={10} paddingY={2} paddingBottom={8}>
            <UnitsTable units={filteredUnits} loading={loading} />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default HealthCenterUnitsReporting;
