import { createMuiTheme } from '@material-ui/core';
import color from './color';
import palette from './palette';
import shadows from './shadows';

const transition: string = '250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms';

const theme = createMuiTheme({
  shadows,
  shape: {
    borderRadius: 8,
  },
  props: {
    MuiCard: {
      elevation: 2,
    },
    MuiIconButton: {
      disableRipple: true,
    },
    MuiIcon: {
      color: 'inherit',
    },
    MuiListSubheader: {
      disableGutters: true,
    },
    MuiInputBase: {
      color: 'secondary',
    },
    MuiInputLabel: {
      color: 'secondary',
    },
    MuiDrawer: {
      PaperProps: {
        elevation: 4,
      },
    },
    MuiInput: {
      color: 'secondary',
    },
    MuiSelect: {
      displayEmpty: true,
      variant: 'outlined',
      color: 'primary',
      fullWidth: true,
    },
    MuiButton: {
      variant: 'contained',
      color: 'primary',
      disableElevation: true,
    },
    MuiSvgIcon: {
      fontSize: 'inherit',
    },
    MuiPaper: {
      elevation: 2,
    },
    MuiDialog: {
      PaperProps: {
        elevation: 0,
      },
    },
    MuiMenu: {
      elevation: 2,
      getContentAnchorEl: null,
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'center',
      },
      transformOrigin: {
        vertical: 'top',
        horizontal: 'center',
      },
    },
    MuiMenuItem: {
      dense: true,
      disableGutters: true,
    },
    MuiTooltip: { arrow: true, placement: 'right' },
  },
  typography: {
    fontFamily: 'Inter,sans-serif',
    h1: {
      lineHeight: '34px',
      fontSize: '24px',
      fontWeight: 800,
    },
    h2: {
      fontSize: '20px',
      lineHeight: '30px',
      fontWeight: 700,
    },
    h3: {
      fontSize: '18px',
      lineHeight: '26px',
      fontWeight: 700,
    },
    h4: {
      fontSize: '16px',
      lineHeight: '24px',
      fontWeight: 600,
    },
    h5: {
      fontSize: '14px',
      lineHeight: '18px',
      fontWeight: 700,
    },
    h6: {
      fontSize: '14px',
      lineHeight: '18px',
      fontWeight: 600,
    },
    subtitle1: {
      fontSize: '16px',
      lineHeight: '16px',
      fontWeight: 500,
    },
    subtitle2: {
      fontSize: '12px',
      lineHeight: '16px',
      fontWeight: 500,
      color: palette.text.secondary,
    },
    body1: {
      fontSize: '14px',
      lineHeight: '18px',
      fontWeight: 400,
    },
    body2: {
      fontSize: '14px',
      lineHeight: '18px',
      fontWeight: 500,
    },
    overline: {
      fontWeight: 500,
      fontSize: '10px',
      lineHeight: '12px',
      letterSpacing: '0.05em',
      textTransform: 'uppercase',
      color: palette.text.secondary,
    },
    button: {
      textTransform: 'initial',
      fontSize: 14,
      fontWeight: 600,
      lineHeight: '18px',
    },
  },
  palette,
  spacing: 4,
  overrides: {
    MuiChip: {
      root: {
        padding: '4px 0',
        borderRadius: 4,
        height: 'auto',
        color: palette.primary.main,
        backgroundColor: palette.primary.light,
      },
      label: {
        paddingRight: 8,
        paddingLeft: 8,
        fontSize: 12,
        fontWeight: 500,
      },
      icon: {
        marginLeft: 0,
        marginRight: 8,
        color: palette.primary.main,
      },
    },
    MuiCardHeader: {
      title: {
        fontWeight: 'bold',
        fontSize: '17px',
        lineHeight: '22px',
      },
      action: {
        alignSelf: 'center',
        marginRight: -8,
        marginBottom: -8,
      },
    },
    MuiSvgIcon: {
      root: {
        verticalAlign: 'middle',
        fontSize: 16,
      },
      fontSizeLarge: {
        fontSize: 24,
      },
      fontSizeSmall: {
        fontSize: 8,
      },
    },
    MuiCircularProgress: {
      svg: {
        strokeLinecap: 'round',
      },
    },
    MuiBackdrop: {
      root: {
        backgroundColor: 'rgba(1, 13, 50, 0.4)',
      },
    },
    MuiOutlinedInput: {
      root: {
        '& .MuiSelect-outlined.MuiSelect-outlined': {
          paddingRight: '36px',
        },
      },
    },
    MuiSelect: {
      root: {
        padding: '11px 12px',
        '& .MuiTypography-root': {
          fontWeight: 500,
          color: color.grey[500],
        },
        '& .MuiTypography-root.active': {
          fontWeight: 600,
          color: palette.secondary.dark,
        },
      },
      iconOutlined: {
        top: 'calc(50% - 7px)',
        right: 14,
      },
    },
    MuiMenu: {
      paper: {
        marginTop: 4,
        boxShadow: '0px 1px 3px rgba(17, 24, 39, 0.1), 0px 1px 2px rgba(17, 24, 39, 0.06)',
      },
      list: {
        padding: 12,
      },
    },
    MuiMenuItem: {
      root: {
        backgroundColor: 'white',
        '&:hover': { backgroundColor: palette.secondary.light },
        '&:focus': { backgroundColor: 'white', '&:hover': { backgroundColor: palette.secondary.light } },
        '&.Mui-selected': {
          backgroundColor: 'white',
          color: palette.primary.main,
          '&:hover': { backgroundColor: palette.secondary.light },
        },
      },
    },

    MuiIconButton: {
      root: {
        transition,
        '&:hover': {
          backgroundColor: 'transparent',
          color: palette.secondary.main,
        },
        color: palette.secondary.dark,
      },
      colorPrimary: {},
    },
    MuiIcon: {
      colorSecondary: {
        fill: palette.secondary.main,
        color: palette.secondary.main,
      },
    },
    MuiButton: {
      text: {
        transition,
        padding: 0,
        '&:hover': {
          backgroundColor: 'transparent',
          color: palette.secondary.main,
        },
        '& > span.MuiTouchRipple-root': {
          display: 'none',
        },
      },
      textPrimary: {
        transition,
        '&:hover': {
          backgroundColor: 'transparent',
          color: palette.primary.dark,
        },
      },
      contained: {
        padding: '15px 24px',
      },
      root: {
        padding: '15px 24px',
      },
      outlined: {
        padding: '15px 24px',
      },
      iconSizeMedium: {
        fontSize: 24,
        '& > *:first-child': {
          fontSize: 'inherit',
        },
      },
      sizeSmall: {
        fontSize: 14,
      },
      iconSizeSmall: {
        fontSize: 16,
        '& > *:first-child': {
          fontSize: 'inherit',
        },
      },
    },
    MuiListItemText: {
      primary: { fontSize: '14px', lineHeight: '18px', fontWeight: 600 },
    },
    MuiDialog: {
      paper: {
        width: 544,
        borderRadius: 8,
        boxShadow: '0px 20px 25px -5px rgba(17, 24, 39, 0.1), 0px 10px 10px -5px rgba(17, 24, 39, 0.04)',
      },
      container: { backgroundColor: 'rgba(1, 13, 50, 0,4)' },
    },
    MuiDialogTitle: {
      root: {
        padding: '40px 40px 0px 40px',
      },
    },
    MuiDialogContent: {
      root: {
        padding: '0px 40px 11px 40px',
      },
    },
    MuiDialogActions: {
      root: {
        padding: '11px 40px 40px 40px',
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: '#E2E8F0',
      },
    },
    MuiCardContent: {
      root: {
        padding: '20px',
        '&:last-child': {
          paddingBottom: '20px',
        },
        '& h6.MuiTypography-subtitle2': {
          color: color.grey[400],
        },
        '& span.MuiTypography-caption': {
          color: color.grey[400],
          fontWeight: 700,
        },
      },
    },
    MuiCollapse: {
      container: {
        width: '100%',
      },
    },
    MuiDrawer: {
      paperAnchorDockedLeft: {
        borderRight: 0,
      },
    },
    MuiListSubheader: {
      root: {
        fontSize: '10px',
        lineHeight: '12px',
        letterSpacing: '0.08em',
        textTransform: 'uppercase',
        fontWeight: 500,
        color: '#0A0D10',
        marginBottom: 8,
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: '28px',
        color: 'inherit',
      },
    },
    MuiLinearProgress: {
      colorPrimary: {
        backgroundColor: color.grey[200],
      },
      root: { borderRadius: 8 },
    },
    MuiTooltip: {
      tooltipArrow: {
        backgroundColor: 'rgba(1, 13, 50, 0.95)',
        padding: '12px 24px 12px 24px',
        fontSize: '12px',
        lineHeight: '16px',
        fontWeight: 500,
        boxShadow: shadows[5],
        '& > div > span': {
          color: color.grey[300],
          fontWeight: 400,
        },
      },
      arrow: {
        color: 'rgba(1, 13, 50, 0.95)',
      },
    },
  },
});

export default theme;
