import Api from '@ambuliz/sabri-core';
import React, { createContext, useEffect, useState } from 'react';
import { NormalizedUser } from 'types';
import { normalizeUser } from 'types/User';
import { getCookie, setCookie, removeCookie } from 'tools/cookie';

const useUserContextProvider = () => {
  const [sessionToken, setSessionToken] = useState<string | undefined>(getCookie('sessionToken'));
  const [currentUser, setCurrentUser] = useState<NormalizedUser | undefined>(normalizeUser(Api.User.current()));

  useEffect(() => {
    const updateCookie = () => setSessionToken(getCookie('sessionToken'));

    let timer: number | undefined;
    const setUserFromCookies = async () => {
      if (sessionToken) {
        try {
          setCurrentUser(normalizeUser(await Api.User.become(sessionToken)));
        } catch (error) {
          removeCookie('sessionToken', window.location.hostname, '/');
          setCurrentUser(undefined);
        }
      } else {
        localStorage.removeItem('Parse/sabri/currentUser');
        setCurrentUser(undefined);

        timer = window.setInterval(updateCookie, 5000);
        window.addEventListener('focus', updateCookie);
      }
    };

    setUserFromCookies();

    return () => {
      clearInterval(timer);
      window.removeEventListener('focus', updateCookie);
    };
  }, [sessionToken]);

  const login = async (username: string, password: string) => {
    const user = await Api.User.logIn(username, password).then((result) => {
      setCookie('sessionToken', Api.User.current()?.getSessionToken(), window.location.hostname, '/');
      setSessionToken(Api.User.current()?.getSessionToken());
      return result;
    });
    setCurrentUser(normalizeUser(user));
  };

  const logout = async () => {
    await Api.User.logOut().then(() => {
      removeCookie('sessionToken', window.location.hostname, '/');
      setSessionToken(undefined);
      setCurrentUser(undefined);
    });
  };

  return { currentUser, login, logout, isLoggedIn: !!currentUser };
};

type UserContextType = ReturnType<typeof useUserContextProvider>;
const UserContext = createContext({} as UserContextType);

export const UserContextProvider = ({ children }: { children: React.ReactNode }) => {
  const value = useUserContextProvider();
  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export default UserContext;
