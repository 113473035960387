import { Box, Grid, Typography } from '@material-ui/core';
import OpenInNewRoundedIcon from '@material-ui/icons/OpenInNewRounded';
import { Button } from 'components/Common';
import MultipleSelect from 'components/Common/Form/Inputs/MultipleSelect';
import { HospitalBedroom } from 'components/Common/Icons';
import { PageHeader } from 'components/Common/Navigation';
import HealthCenterMap from 'components/Dashboard/Map/Map';
import { StatisticsCard, StatisticsCardItem } from 'components/Dashboard/Statistics';
import { i18n } from 'locale';
import Layout from 'modules/Layout';
import { useEffect, useState } from 'react';

const TerritorialReporting = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => setLoading(false), 1000);
    return () => clearTimeout(timer);
  }, []);

  const [healthCenterFilter, setHealtCenterFilter] = useState<string[]>([]);
  const [channelFilter, setChannelFilter] = useState<string[]>([]);
  const [tensionFilter, setTensionFilter] = useState<string[]>([]);

  return (
    <Layout>
      <PageHeader title="Reporting GHT de la Dordogne">
        <Button variant="outlined" endIcon={<OpenInNewRoundedIcon />}>
          {i18n.exportData}
        </Button>
      </PageHeader>
      <Grid item>
        <Box padding={6} bgcolor="secondary.light" borderRadius={8}>
          <Grid container spacing={6}>
            <Grid item xs={12} md="auto">
              <StatisticsCard loading={loading} title={i18n.globalOccupancy}>
                <StatisticsCardItem
                  loading={loading}
                  label={i18n.occupancyRate}
                  value={84}
                  displayProgress
                  percentage
                  Icon={HospitalBedroom}
                />
                <StatisticsCardItem
                  loading={loading}
                  label={i18n.numberOfBeds}
                  value={336}
                  total={400}
                  totalLabel="lits"
                />
              </StatisticsCard>
            </Grid>
            <Grid item xs={12} md="auto">
              <StatisticsCard loading={loading} title="Occupation par filière">
                <StatisticsCardItem label="Médecine" value="84" percentage />
              </StatisticsCard>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid container item direction="column" spacing={4}>
        <Grid item>
          <Typography variant="h3">Synthèse du territoire</Typography>
        </Grid>
        <Grid item container spacing={2}>
          <Grid item>
            <MultipleSelect
              label="Établissements"
              options={{ chir: 'Chirurgie', med: 'Médecine', urg: 'Urgences' }}
              value={healthCenterFilter}
              onChange={setHealtCenterFilter}
            />
          </Grid>
          <Grid item>
            <MultipleSelect
              label="Filières"
              options={{ chir: 'Cardiologie', med: 'Pneumologie', urg: 'Gériatrie' }}
              value={channelFilter}
              onChange={setChannelFilter}
            />
          </Grid>
          <Grid item>
            <MultipleSelect
              label="Tension"
              options={{ 0: '< 70%', 70: '> 70%', 90: '> 90%' }}
              value={tensionFilter}
              onChange={setTensionFilter}
            />
          </Grid>
        </Grid>
        <Grid item container>
          <Box marginX={-10} marginBottom={-10} width="calc(100% + 80px)">
            <HealthCenterMap />
          </Box>
        </Grid>
      </Grid>
    </Layout>
  );
};

export default TerritorialReporting;
