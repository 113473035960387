import { Box, Button, Divider, Grid } from '@material-ui/core';
import { Group, HospitalBedroom } from 'components/Common/Icons';
import Download from 'components/Common/Icons/Download';
import PageHeader from 'components/Common/Navigation/PageHeader/PageHeader';
import { StatisticsCard, StatisticsCardItem } from 'components/Dashboard/Statistics';
import { i18n } from 'locale';
import { HealthCenterUnitsReporting } from 'modules/HealthCenterUnitsReporting';
import Layout from 'modules/Layout';
import { useEffect, useState } from 'react';

const Home = () => {
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const timer = setTimeout(() => setLoading(false), 1000);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <Layout>
      <PageHeader title="Centre hospitalier de Périgueux">
        <Button variant="outlined" endIcon={<Download />}>
          {i18n.exportData}
        </Button>
      </PageHeader>
      <Grid item>
        <Box padding={6} bgcolor="secondary.light" borderRadius={8}>
          <Grid container spacing={6}>
            <Grid item xs={12} md="auto">
              <StatisticsCard loading={loading} title={i18n.globalOccupancy}>
                <StatisticsCardItem
                  loading={loading}
                  label={i18n.occupancyRate}
                  value={84}
                  displayProgress
                  percentage
                  Icon={Group}
                />
                <Grid item>
                  <Divider orientation="vertical" />
                </Grid>
                <StatisticsCardItem
                  loading={loading}
                  label={i18n.numberOfBeds}
                  value={336}
                  total={400}
                  totalLabel="lits"
                />
              </StatisticsCard>
            </Grid>
            <Grid item xs={12} md="auto">
              <StatisticsCard loading={loading} title={i18n.emergenciesOccupation} action>
                <StatisticsCardItem
                  loading={loading}
                  label={i18n.total}
                  value={28}
                  displayProgress
                  Icon={HospitalBedroom}
                />
                <Grid item>
                  <Divider orientation="vertical" />
                </Grid>

                <StatisticsCardItem loading={loading} label={i18n.numberOfBeds} value="5" />
                <Grid item>
                  <Divider orientation="vertical" />
                </Grid>
                <StatisticsCardItem loading={loading} label={i18n.estimatedWaitingTime} value="1h30" />
              </StatisticsCard>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid item>
        <HealthCenterUnitsReporting />
      </Grid>
    </Layout>
  );
};

export default Home;
